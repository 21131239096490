import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import { ContentSection, DesktopOnly, HR, Layout, SEO } from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: '404 Not Found',
  order: 120,
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout noTray>
      <SEO
        title="404 │ NERLYNX® (neratinib) tablets"
        description="Page not found."
        path={props.location?.pathname}
      />
      <DesktopOnly>
        <HR cyan customCss={tw`h-16`} />
      </DesktopOnly>
      <ContentSection
        customCss={tw`height[calc(100vh - 400px)] md:(height[calc(100vh - 350px)])`}
      >
        <h1 tw="text-xl text-brand-blue font-primary grid justify-center content-center">
          Page not found.
        </h1>
      </ContentSection>
    </Layout>
  );
};

export default Page;
